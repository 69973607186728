export function isMobileDevice() {
  return isMobileDevice2();
}

export function isMobileDevice2() {
  const debug = false;
  if (debug) {
    return true;
  } else {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);

    return (flag !== null);
  }
}