import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import { isMobileDevice } from '@/utils/utils';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../App.vue'),
    redirect: '/main',
    children: [
      {
        path: 'main',
        name: '首页',
        component: () => import('../views/PC/Main/main.vue'),
      },
      {
        path: 'guinong',
        name: '归农集团',
        component: () => import('../views/PC/Main/main.vue'),
        beforeEnter(to, from, next) {
          window.location.href = "http://www.hzno.cn";
        },
      },
      {
        path: 'brand',
        name: '品牌文化',
        component: () => import('../views/PC/Brand/index.vue'),
      },
      {
        path: 'base',
        name: '万亩基地',
        component: () => import('../views/PC/Base/index.vue'),
      },
      {
        path: 'aloe',
        name: '芦荟护肤',
        component: () => import('../views/PC/Aloe/index.vue'),
      },
      {
        path: 'prodev',
        name: '生产研发',
        component: () => import('../views/PC/Prodev/index.vue'),
      },
      {
        path: 'joinus',
        name: '加入万非',
        component: () => import('../views/PC/Joinus/index.vue'),
      },
    ],
  },
];

const mobileRouters: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Main',
    component: () => import('../AppMobile.vue'),
    redirect: '/main',
    children: [
      {
        path: 'main',
        name: '首页',
        component: () => import('../views/Mobile/Main/main.vue'),
      },
      {
        path: 'guinong',
        name: '归农集团',
        component: () => import('../views/Mobile/Main/main.vue'),
        beforeEnter(to, from, next) {
          window.location.href = "http://www.hzno.cn/#/home";
        },
      },
      {
        path: 'brand',
        name: '品牌故事',
        component: () => import('../views/Mobile/Brand/index.vue'),
      },
      {
        path: 'base',
        name: '万亩基地',
        component: () => import('../views/Mobile/Base/index.vue'),
      },
      {
        path: 'aloe',
        name: '芦荟护肤',
        component: () => import('../views/Mobile/Aloe/index.vue'),
      },
      {
        path: 'prodev',
        name: '生产研发',
        component: () => import('../views/Mobile/Prodev/index.vue'),
      },
      {
        path: 'joinus',
        name: '加入万非',
        component: () => import('../views/Mobile/Joinus/index.vue'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: isMobileDevice() ? mobileRouters : routes,
});

export default router;
