<template>
  <router-view />
</template>

<script>
import { Options, Vue } from "vue-class-component";

@Options({
  data() {
    return {};
  },
})
export default class Root extends Vue {}
</script>

